/*@font-face {*/
/*    font-family: "ONEMobilePOP", "GodoM";*/
/*    font-display: fallback;*/

/*    !*src: 사용할 폰트의 위치 및 형식*!*/
/*    !*   Safari, Android, iOS => ttf*!*/
/*    !*   Modern Browsers => woff*!*/
/*    !*   IE6-IE8 => eot*!*/

/*    src: url("./ONE-Mobile-POP.ttf") format("mobileFont"), url("./ONE-Mobile-POP-OTF.otf")*/
/*    format("mobileFont2");*/

    @font-face {
        font-family: "GodoM";
        src: url("./ONE-Mobile-POP-OTF.otf");
    }
@font-face {
        font-family: "ONEMobilePOP";
        src: url("./ONE-Mobile-POP.ttf");
    }

    /*!* 사용할 폰트의 기본 스타일 *!*/
    /*font-weight: 400;*/
    /*font-style: normal;*/